/* ==========================================================================
   Project Fonts
   ========================================================================== */

@font-face {
    font-family: 'radikal';
    src:  url('../fonts/radikal-light.eot');
    src:  url('../fonts/radikal-light.eot?#iefix') format('embedded-opentype'),
          url('../fonts/radikal-light.woff2') format('woff2'),
          url('../fonts/radikal-light.woff') format('woff'),
          url('../fonts/radikal-light.ttf') format('truetype');
	font-weight:  300;
	font-style:   normal;
	font-stretch: normal;
	font-display: fallback;

}

@font-face {
    font-family: 'radikal';
    src:  url('../fonts/radikal-lightitalic.eot');
    src:  url('../fonts/radikal-lightitalic.eot?#iefix') format('embedded-opentype'),
          url('../fonts/radikal-lightitalic.woff2') format('woff2'),
          url('../fonts/radikal-lightitalic.woff') format('woff'),
          url('../fonts/radikal-lightitalic.ttf') format('truetype');
	font-weight:  300;
	font-style:   italic;
	font-stretch: normal;
	font-display: fallback;

}


@font-face {
    font-family: 'radikal';
    src:  url('../fonts/radikal-black.eot');
    src:  url('../fonts/radikal-black.eot?#iefix') format('embedded-opentype'),
          url('../fonts/radikal-black.woff2') format('woff2'),
          url('../fonts/radikal-black.woff') format('woff'),
          url('../fonts/radikal-black.ttf') format('truetype');
	font-weight:  600;
	font-style:   normal;
	font-stretch: normal;
	font-display: fallback;
}

