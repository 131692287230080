/* Variables, Naming may vary from project to project */

/* ==========================================================================
   Fonts
   ========================================================================== */

$font-stack-default: 'radikal', 'Arial', 'Helvetica', sans-serif;


/* ==========================================================================
   Colors
   ========================================================================== */

/* text */
$text-color: #2A2228; /* grey */
$text-color-light: #fff;
$text-color-medium: #605D6A;
$text-color-footer: #d6d6d6;

$color-primary: #2E1AA9; /* blue */
$color-highlight: #00D4B4; /* green */

/* background */
$bg-content: #fff;
$bg-dark: #333333; /* grey */
$bg-light: #E2E5E9; /* grey */


/* line-colors */
$border-basic: #E2E5E9;
$border-light: #fff;
$border-dark: #59585c;



/* ==========================================================================
   Breakpoints
   ========================================================================== */
